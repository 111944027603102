<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div>
        <div class="wrap">
          <h1 style="padding-right: 60px">
            Паспорт офиса ЦЗН
            <span>Для сети ЦЗН централизованной или смешанной модели управления под ЦЗН (офисом ЦЗН)
              понимается точка приема либо управляющий ЦЗН</span>
            <RegionAndDepartmentPicker v-model="regionAndDepartmentInfo"
                                       :nullable-department="false"
            ></RegionAndDepartmentPicker>

            <div class="download-menu">
              <v-dialog
                  v-model="passportCZNExportDialog"
                  max-width="600px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"
                      />
                    </svg>
                  </div>
                </template>

                <PassportCZNExportDialog
                    @close="passportCZNExportDialog=false; exportDialogKey++"
                    :is-from-config="false"
                    :region="regionAndDepartmentInfo.region?.name"
                    :czn="regionAndDepartmentInfo.department"
                    :key="exportDialogKey"/>
              </v-dialog>
            </div>

            <div v-if="$user.role !== 'ROLE_CZN_TERRITORIAL'">
              <v-checkbox
                  v-model="disableEditingToTerritorialCZN"
                  @click="changeEditStatus()"
                  label="Запретить редактирование территориальным ЦЗН"
                  style="float: left; margin-top: 0"
              ></v-checkbox>
<!--              <v-btn-->
<!--                  @click="$router.push('/passportczn/massEdit')"-->
<!--                  outlined-->
<!--                  style="float: right">-->
<!--                Указать данные по всем ЦЗН региона-->
<!--              </v-btn>-->
            </div>
          </h1>

          <template v-if="apiLoaded">
            <router-link
                :to="'/clientindex?reg='+regionAndDepartmentInfo.region?.code + '&czn=' + regionAndDepartmentInfo.department?.id"
                style="text-decoration: none">
              <div class="clientindex passportinfo">
                <div class="row top-progress">
                  <div class="col-lg-4 col">
                    <div class="green">
                      <div>{{ floor2(iccDashboardData.I) }}<span class="text-caption">/50</span></div>
                      <p> Индекс<br>клиентоцентричности </p></div>
                  </div>
                  <div class="col-lg-4 col">
                    <div class="blue">
                      <div>{{ floor2(iccDashboardData.Is) }}<span class="text-caption">/50</span></div>
                      <p> Индекс клиентоцентричности с точки зрения соискателей </p></div>
                  </div>
                  <div class="col-lg-4 col">
                    <div class="yellow">
                      <div>{{ floor2(iccDashboardData.Ir) }}<span class="text-caption">/50</span></div>
                      <p> Индекс клиентоцентричности с точки зрения работодателей </p></div>
                  </div>
                </div>
              </div>
            </router-link>

            <div v-if="editable && photos.length < 20" class="mb-5">
              <v-btn outlined @click="$refs['photoInput'].click()">добавить фото</v-btn>
              <input ref="photoInput" style="display: none" type="file" @change="addPhoto()">
            </div>

            <div>
              <VueSlickCarousel v-bind="settings" v-if="getFiles(cznPhotosIndicatorId).length > 0" :key="componentKey">
                <div v-for="(photo, photoIndex) in getFiles(cznPhotosIndicatorId)"
                     :key="photoIndex + 'photoIndex'"
                     style="position: relative">
                  <img :src="resultPhotos[photoIndex]" alt="" class="photo"/>
                  <span> <!-- style="display: flex; flex-direction: row!important; justify-content: center" -->
                  <v-btn class="round-btn delbtn" @click="downloadPhoto(photoIndex)" title="Скачать фото">
                    <v-icon class="icon-item icon-tray-arrow-down"></v-icon>
                  </v-btn>
                  <v-btn v-if="editable" class="round-btn delbtn" @click="confirmToDelete(photo.id)"
                         title="Удалить фото">
                    <v-icon class="icon-item icon-window-close"></v-icon>
                  </v-btn>
                </span>
                </div>
              </VueSlickCarousel>
            </div>

            <div class="list passport">
              <div
                  v-for="(section, sectionIndex) in sections"
                  :key="'liw' + sectionIndex"
                  class="list-item-wrap"
              >
                <v-expansion-panels>
                  <v-expansion-panel :key="'vep' + sectionIndex">
                    <v-expansion-panel-header>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch">
                            <svg
                                class="closed"
                                fill="none"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                                  fill="#1551D0"
                              />
                            </svg>
                            <svg
                                class="opened"
                                fill="none"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                                  fill="#1551D0"
                              />
                            </svg>
                          </button>
                          <span>{{ sectionIndex + 1 }}. {{ section.name }}</span>
                        </a>
                        <v-progress-circular
                            :rotate="-90"
                            :size="50"
                            :value="getProgress(sectionIndex)"
                            :width="6"
                            color="#0033A0"
                        >
                          {{ getProgress(sectionIndex) }}
                        </v-progress-circular>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <table>
                        <thead>
                        <tr>
                          <th colspan="2">Показатели (данные)</th>
                          <th style="width: 300px;">
                            {{ period ? getPeriodName(period) : 'Текущий период' }}
                          </th>
                          <th>
                            <svg
                                @click="previousPeriod()"
                                style="cursor: pointer"
                                fill="none"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                                  fill="#0033A0"
                              />
                            </svg>
                            <svg
                                @click="nextPeriod()"
                                style="cursor: pointer"
                                fill="none"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                                  fill="#0033A0"
                              />
                            </svg>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(indicator, indicatorIndex) in section.indicators">
                          <tr :key="sectionIndex + '.' + indicatorIndex">
                            <td>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}</td>
                            <td>{{ indicator.name }}</td>
                            <td>
                              {{ getValueRepresentation(indicator) }}
                                <br>
                                <div class="additional-info">
                                  <div v-if="getRelevanceDate(indicator.id)">
                                    {{ getRelevanceDate(indicator.id) | dateFormat }}
                                  </div>
<!--                                  <v-icon small class="icon-item icon-comment-outline" v-if="getValue(indicator.id, 'COMMENT') != null"></v-icon>-->
                                  <div v-for="attachment in getFiles(indicator.id)" :key="attachment.id">
                                    <a class="docs-attach" @click.prevent="downloadAttachment(attachment)">{{truncateStr(attachment.name, 30)}}</a>
                                  </div>
                                </div>
                            </td>
                            <td>
                              <PassportCZNIndicatorEditButton
                                  :domain="domain"
                                  :indicator-config="indicator"
                                  :region-and-department-info="regionAndDepartmentInfo"
                                  :min-period="minPeriod"
                                  :max-period="maxPeriod"
                                  :period-span="periodSpan"
                                  :editable="editable"
                                  :disableEditingToTerritorialCZN="disableEditingToTerritorialCZN"
                                  @updated="passportCZNIndicatorUpdated"
                              />
                            </td>
                          </tr>
                          <tr v-for="(subIndicator, subIndicatorIndex) in indicator.subIndicators"
                              :key="sectionIndex + '.' + indicatorIndex + '.' + subIndicatorIndex">
                            <td>
                              {{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}.{{ subIndicatorIndex + 1 }}
                            </td>
                            <td>{{ subIndicator.name }}</td>
                            <td>
                              {{ getValueRepresentation(subIndicator) }}
                              <br>
                              <div class="additional-info">
                                <div v-if="getRelevanceDate(subIndicator.id)">
                                  {{ getRelevanceDate(subIndicator.id) | dateFormat }}
                                </div>
<!--                                <v-icon class="icon-item icon-comment-outline"></v-icon>-->
                                <div v-for="attachment in getFiles(subIndicator.id)" :key="attachment.id">
                                  <a class="docs-attach" @click.prevent="downloadAttachment(attachment)">{{truncateStr(attachment.name, 30)}}</a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <PassportCZNIndicatorEditButton
                                  :domain="domain"
                                  :indicator-config="subIndicator"
                                  :region-and-department-info="regionAndDepartmentInfo"
                                  :min-period="minPeriod"
                                  :max-period="maxPeriod"
                                  :period-span="periodSpan"
                                  :editable="editable"
                                  :disableEditingToTerritorialCZN="disableEditingToTerritorialCZN"
                                  @updated="passportCZNIndicatorUpdated"
                              />
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <loader-dialog :show="loaderDialog" msg="Загрузка файла"></loader-dialog>
            </div>
          </template>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from "@/modules/api";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css"
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"
import PassportCZNExportDialog from "@/components/dialogs/PassportCZNExportDialog";
import {loadCznDashboardData} from "@/modules/IccRepository";
import {
  getActiveDomainConfigs,
  getDomainConfigById,
} from "@/modules/api.configs";
import {
  getPeriodByDate,
  getPeriodName,
  nextPeriodRoundRobin, previousPeriod,
  previousPeriodRoundRobin
} from "@/modules/periods";
import PassportCZNIndicatorEditButton from "./PassportCznIndicatorEditButton";
import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";
import {getCznIndicatorsByPeriod, saveIndicators} from "@/modules/api.indicators";
import {downloadFile, uploadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog";


export default {
  name: "PassportCZNInfo",
  components: {
    RegionAndDepartmentPicker,
    PassportCZNIndicatorEditButton,
    PassportCZNExportDialog,
    VueSlickCarousel,
    LoaderDialog
  },

  data() {
    return {
      domain: 'passport_czn', // домен в едином хранилище показателей
      cznPhotosIndicatorId: '033a0047-eecb-4556-bee1-41c654e87d93', // индикатор показателей-фотографий цзн
      periodSpan: 'YEAR', // как устроены периоды для показателей
      period: null, // текущий выбранный период
      minPeriod: '2020', // с какого периода начинается заполнение
      maxPeriod: null, // максимальный период (предполагается предшествующий текущему)
      sections: null, // секции показателей из конфига
      regionAndDepartmentInfo: {region: null, department: null}, // выбранный регион и цзн
      loaderDialog: false,
      apiLoaded: false,
      editable: false,
      disableEditingToTerritorialCZN: false,
      errorText: null,
      dialog: false,
      photos: [],
      resultPhotos: [],
      iccDashboardData: {},
      originalPhotoNames: [],
      componentKey: 0,
      editDialogKey: 0,
      exportDialogKey: 0,
      availableImageType: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/tiff',
        'image/webp',
        'image/avif',
        'image/bmp'
      ],
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      passportCZNExportDialog: false,
    };
  },

  methods: {
    getPeriodName: getPeriodName,
    nextPeriod() {
      this.period = nextPeriodRoundRobin(this.period, this.minPeriod, this.maxPeriod, this.periodSpan, true)
    },

    previousPeriod() {
      this.period = previousPeriodRoundRobin(this.period, this.minPeriod, this.maxPeriod, this.periodSpan, true)
    },

    async handleRegionOrDepartmentOrPeriodChange() {
      this.apiLoaded = false;
      await this.loadValues();
      await loadCznDashboardData(3, this.regionAndDepartmentInfo.department.id)
      this.$forceUpdate();
      this.apiLoaded = true;
      await this.updatePhotos()
    },

    async loadValues() {
      this.passportCznValues = []
      let req = await getCznIndicatorsByPeriod(this.domain,
          this.regionAndDepartmentInfo.department.id,
          this.period
      )
      if(!req.ok){
        alert('Ошибка получения данных')
        return
      }
      this.passportCznValues = req.payload
      console.log(this.passportCznValues)
    },

    // значение из апи
    getValue(indicatorId, type) {
      return this.passportCznValues.find(e => e.indicatorId===indicatorId && e.type===type)?.value
    },

    //как значение из апи показывать на экране
    getValueRepresentation(indicator){
      const val = this.getValue(indicator.id, 'VALUE')
      if(val == null){
        return '—'
      }
      if(indicator.dataType === 'OPTIONAL'){
        return indicator.options.find(it => it.id == val)?.value || 'Ошибочное значение'
      }
      return val
    },

    getFiles(indicatorId){
      const filesJson = this.getValue(indicatorId, 'FILES')
      try{
        return JSON.parse(filesJson)
      } catch (e){
        return []
      }
    },

    async downloadAttachment(attachment) {
      this.loaderDialog = true
      await downloadFile(attachment.id, attachment.name)
      this.loaderDialog = false
    },

    async passportCZNIndicatorUpdated(){
      await this.loadValues()
      this.$forceUpdate()
    },

    getRelevanceDate(){
      return null
    },
    //
    //
    // getRelevanceDate(sectionId, indicatorId, subIndicatorId) {
    //   for (let v of this.values) {
    //     if (
    //         v.sectionId === sectionId &&
    //         v.indicatorId === indicatorId &&
    //         v.subIndicatorId === subIndicatorId
    //     ) {
    //       if (v.year === this.showYear) return v.relevanceDate;
    //     }
    //   }
    // },
    //
    // checkAdditionalInfo(sectionId, indicatorId, subIndicatorId, type) {
    //   let info = this.getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId);
    //   if (info != null) {
    //     if (type === "FILE") {
    //       return info.storedFile
    //     } else if (type === "COMMENT") {
    //       return info.comment
    //     }
    //   } else {
    //     return false
    //   }
    // },
    //
    // getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId) {
    //   if (this.indicatorsEditInfo == null) return null;
    //   for (let v of this.indicatorsEditInfo) {
    //     if (
    //         v.sectionId === sectionId &&
    //         v.indicatorId === indicatorId &&
    //         v.subIndicatorId === subIndicatorId
    //     ) {
    //       return v;
    //     }
    //   }
    // },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async loadActiveConfig() {
      let req = await getActiveDomainConfigs(this.domain)
      if (req.ok) {
        let configs = req.payload
        if (configs.length === 0) {
          alert('Ошибка. Нет активной конфигурации паспорта ЦЗН')
          return
        } else if (configs.length > 1) {
          alert('Ошибка. Найдено более одной конфигурации паспорта ЦЗН')
          return
        }
        this.sections = configs[0].content.sections
      } else {
        alert('Ошибка загрузки данных')
      }
    },

    async loadConfigById() {
      let req = await getDomainConfigById(this.domain, this.configId)
      if (req.ok) {
        let config = req.payload
        this.sections = config.content.sections
      } else {
        alert('Ошибка загрузки данных')
      }
    },

    async loadConfig() {
      if (this.configId == null) {
        await this.loadActiveConfig()
      } else {
        await this.loadConfigById()
      }
    },

    filterValues(sectionId, indicatorId, subIndicatorId) {
      let resv = [];
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        )
          resv.push({
            region: v.region,
            configId: v.configId,
            sectionId: v.sectionId,
            indicatorId: v.indicatorId,
            subIndicatorId: v.subIndicatorId,
            czn: v.czn,
            relevanceDate: v.relevanceDate,
            year: v.year,
            value: v.value,
          });
      }
      return resv;
    },

    getSubconfig() {
      // for (let section of this.config.sections) {
      //   if (section.id === sectionId) {
      //     for (let indicator of section.indicators) {
      //       if (indicator.id === indicatorId) {
      //         if (subIndicatorId == null) {
      //           return indicator;
      //         }
      //         for (let subIndicator of indicator.subIndicators) {
      //           if (subIndicator.id === subIndicatorId) return subIndicator;
      //         }
      //       }
      //     }
      //   }
      // }
    },

    getProgress(sectionIndex) {
      return Math.round(
          (this.getSectionValuesPresent(sectionIndex) /
              this.getSectionValuesCount(sectionIndex, this.period != null)) *
          100
      );
    },

    // сколько показателей и подпоказателей в секции есть
    // yearly - учитываются только показатели с yearly=true
    getSectionValuesCount(sectionIndex, yearly) {
      let section = this.sections[sectionIndex]
      return section.indicators.filter(e => !yearly || e.yearly).length + //количество показателей
          section.indicators.reduce((acc, val) => acc + (val.subIndicators?.filter(e => !yearly || e.yearly).length || 0), 0) // плюс количество подпоказателей
    },

    // сколько показателей и подпоказателей в секции заполнено
    getSectionValuesPresent(sectionIndex) {
      let section = this.sections[sectionIndex]
      return section.indicators.reduce((acc, indicator) =>
              (this.getValue(indicator.id, 'VALUE') != null ? acc+1 : acc) + // если показатель заполнен - +1
              (indicator.subIndicators?.reduce((subAcc, subIndicator) => // повторяем то же для субиндикаторов
                      this.getValue(subIndicator.id, 'VALUE') != null ? subAcc+1 : subAcc, 0)
                  || 0) //если subIndicators=undefined, 0
          , 0)
    },

    async getEditStatus() {
      let req = await this.$getApi("/passportCzn/isEditable",
          {departmentId: this.regionAndDepartmentInfo.department?.id});
      if (req.ok) {
        this.disableEditingToTerritorialCZN = !req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async changeEditStatus() {
      let req = await api.postJson(
          "/passportCzn/changeEditable",
          null,
          {departmentId: this.regionAndDepartmentInfo.department?.id, status: !this.disableEditingToTerritorialCZN});
      if (req.ok) {
        this.disableEditingToTerritorialCZN = !req.payload.editable;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async addPhoto() {
      let file = this.$refs['photoInput'].files[0];
      if (!this.availableImageType.includes(file.type)) {
        alert('Загрузите, пожалуйста, файл в одном из допустимых форматов изображения: \n' +
            ' jpg, png, webp, avif, gif, svg, tiff, bmp');
        this.$refs['photoInput'][0].value = null;
        return
      }

      this.loaderDialog = true
      const fileInfo = await uploadFile(file, 20)
      const photos = this.getFiles(this.cznPhotosIndicatorId)
      photos.push({id: fileInfo.id, name: fileInfo.name})

      const newPhotosIndicatorValues = [
        {
          indicatorId: this.cznPhotosIndicatorId,
          regionCode: this.regionAndDepartmentInfo.region.code,
          cznId: this.regionAndDepartmentInfo.department.id,
          period: null,
          value: JSON.stringify(photos),
          type: 'FILES'
        }
      ]
      console.log('newPhotosIndicatorValues', newPhotosIndicatorValues)
      let req = await saveIndicators(newPhotosIndicatorValues)
      if (!req.ok) {
        alert('Ошибка сохранения данных')
        return
      }
      this.$refs['photoInput'].value = null;
      await this.loadValues();
      this.loaderDialog = false
      await this.updatePhotos()
    },

    async updatePhotos() {
      this.resultPhotos = [];
      for (let photo of this.getFiles(this.cznPhotosIndicatorId)) {
        let pic = await this.getPhoto(photo.id);
        this.resultPhotos.push(pic);
      }
    },

    async getPhoto(id) {
      let req = await api.get("/files/"+id, null, "blob");
      if (req.ok) {
        // return req.payload;
        return URL.createObjectURL(req.payload);
      } else {
        alert("Ошибка загрузки данных");
      }
    },

    confirmToDelete(id) {
      let result = confirm('Вы уверены, что хотите удалить фотографию?');
      if (result) {
        this.deletePhoto(id);
      }
    },

    downloadPhoto(photoIndex) {
      let link = document.createElement('a');
      link.href = this.resultPhotos[photoIndex];
      link.download = this.getFiles(this.cznPhotosIndicatorId)[photoIndex].name;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async deletePhoto(id) {
      const photos = this.getFiles(this.cznPhotosIndicatorId)
      photos.splice(photos.findIndex(e=>e.id===id), 1)
      const newPhotosIndicatorValues = [
        {
          indicatorId: this.cznPhotosIndicatorId,
          regionCode: this.regionAndDepartmentInfo.region.code,
          cznId: this.regionAndDepartmentInfo.department.id,
          period: null,
          value: JSON.stringify(photos),
          type: 'FILES'
        }
      ]
      let req = await saveIndicators(newPhotosIndicatorValues)
      if (!req.ok) {
        alert('Ошибка сохранения данных')
        return
      }
      this.$refs['photoInput'].value = null;
      await this.loadValues();
      this.loaderDialog = false
      await this.updatePhotos()
    },

    floor2(val) {
      if (!val) return 0
      return Math.floor(val * 100) / 100
    },

    setDepartment(departmentId){
      let czn = this.$cznDepartmentsList.find(e=>e.id.toString() === departmentId)
      if(czn) {
        this.regionAndDepartmentInfo = {
          region: czn.region,
          department: czn
        }
      }
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.configId = this.$route.params.configId;
    let departmentId = this.$route.params.departmentId
    if(departmentId){
      this.setDepartment(departmentId)
    }
    this.maxPeriod = previousPeriod(getPeriodByDate(this.$serverDateTime, this.periodSpan), this.periodSpan)
    await this.loadConfig();
    // на текущем моменте не выбрана регион и цзн
    // они будут заполнены, в вотч сработает хэндл, там будет this.loadData
    //await this.loadData();

    this.editable = this.$user.role === 'ROLE_CZN_MANAGER'
        || this.$user.role === 'ROLE_CZN_TERRITORIAL'
        || this.$user.role === 'ROLE_ADMIN';
  },
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        await this.handleRegionOrDepartmentOrPeriodChange()
      },
      deep: true
    },
    period: {
      async handler() {
        await this.loadValues()
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
.download-menu {
  top: 20px;
}

.photo {
  max-width: 400px;
  max-height: 200px;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }

  .v-icon {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    color: #777779;
  }

  a {
    font-size: 12px;
  }
}

.delbtn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  margin-left: 100px;

  .v-icon {
    font-size: 16px;
    color: #0033A0;
  }
}

.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}
</style>
