<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">{{ indicatorConfig.name }}</span>
      <a class="close" @click="$emit('close')">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>

        <v-row>

          <v-col cols="12">
            <h4>Данные на текущий момент</h4>
          </v-col>

          <v-col cols="12">
            <PassportCZNIndicatorEditBox
                :value="getValue(null, 'VALUE')"
                :period="null"
                :type="indicatorConfig.dataType"
                :options="indicatorConfig.options"
                @change="setValue"
            ></PassportCZNIndicatorEditBox>
          </v-col>

          <template v-if="indicatorConfig.yearly">
            <v-col cols="12">
              <h4>Данные по годам</h4>
            </v-col>

            <v-col cols="12" v-for="periodOption in periods" :key="periodOption['value']">
              <PassportCZNIndicatorEditBox
                  :value="getValue(periodOption['value'], 'VALUE')"
                  :period="periodOption['value']"
                  :type="indicatorConfig.dataType"
                  :options="indicatorConfig.options"
                  @change="setValue"
              ></PassportCZNIndicatorEditBox>
            </v-col>
          </template>

          <v-col cols="12">
            <h4>Комментарий</h4>
            <v-textarea
                placeholder="Напишите комментарий (необязательно)"
                :value="getValue(null, 'COMMENT')"
                @change="setValue($event, null, 'COMMENT')"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <h4>Вложения</h4>

            <div v-for="attachment in getFiles()" :key="attachment.id">
              <a class="docs-attach" @click.prevent="downloadAttachment(attachment)">{{truncateStr(attachment.name, 30)}}</a>
              <a style="margin-left: 8px; font-weight: bold" @click.prevent="deleteAttachment(attachment)">x</a>
            </div>

            <a href="#" @click.prevent="$refs['fileupload'].click()">Добавить файл</a>
            <input type="file" ref="fileupload" style="display:none" @change="addAttachment($event.target.files[0])">
          </v-col>
<!--          <v-col v-else cols="12">-->
<!--            <a href="#" @click.prevent="downloadAttachment">{{ editInfo.storedFile.name }}</a>-->
<!--          </v-col>-->

<!--          <v-col cols="12">-->
<!--            <p v-if="editInfo">Последние изменения: {{ editInfo.edited | dateTimeFormat }}</p>-->
<!--            <p v-else>Последние изменения: &mdash;</p>-->
<!--          </v-col>-->

        </v-row>
      </v-container>
    </v-card-text>

    <div v-if="disableEditingToTerritorialCZN && this.$user.role === 'ROLE_CZN_TERRITORIAL'"
         style="color: red; margin: 0 20px">
      Редактирование запрещено региональным администратором.
      Дальнейшее редактирование возможно только по согласованию с региональным администратором.
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          class="btn btn-outline"
          text
          :disabled="submitButtonDisabled || !editable || !isValidValuesList()"
          @click="submit()"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
    <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
      Ошибка: {{ errorText }}
    </div>
    <loader-dialog :show="loaderDialog" msg="Загрузка файла"></loader-dialog>
  </v-card>
</template>

<script>
import PassportCZNIndicatorEditBox from "./PassportCZNIndicatorEditBox";
import {getCznIndicatorsByIndicatorId, saveIndicators} from "@/modules/api.indicators";
import {formatDate, formatTimestamp, truncateStr} from "@/modules/CommonUtils";
import {generatePeriodsBetween} from "@/modules/periods";
import {downloadFile, uploadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog";

export default {
  name: 'PassportCznIndicatorEditDialog',
  components: {LoaderDialog, PassportCZNIndicatorEditBox},
  props: [
    'domain',
    'indicatorConfig',
    'regionAndDepartmentInfo',
    'minPeriod',
    'maxPeriod',
    'periodSpan',
    'editable',
    'disableEditingToTerritorialCZN'
  ],
  data: () => ({
    passportCznValues: [],
    periods: [],
    editInfo: {},
    submitButtonDisabled: false,
    errorText: null,
    apiLoaded: false,
    loaderDialog: false
  }),
  methods: {
    truncateStr: truncateStr,
    getValue(period, type) {
      return this.passportCznValues.find(e => e.period===period  && e.type===type)?.value
    },

    deleteValue(period, type) {
      for (let i = 0; i < this.passportCznValues.length; i++) {
        if (this.passportCznValues[i].period === period && this.passportCznValues[i].type === type) {
          this.passportCznValues.splice(i, 1)
        }
      }
    },

    setValue(value, period, type) {
      this.deleteValue(period, type)
      this.passportCznValues.push({
        indicatorId: this.indicatorConfig.id,
        regionCode: this.regionAndDepartmentInfo.region.code,
        cznId: this.regionAndDepartmentInfo.department.id,
        period: period,
        value: value,
        type: type
      })
      this.$forceUpdate()
    },

    getFiles(){
      const filesJson = this.getValue(null, 'FILES')
      return filesJson ? JSON.parse(filesJson) : []
    },

    setFiles(files){
      this.deleteValue(null, 'FILES')
      this.passportCznValues.push({
        indicatorId: this.indicatorConfig.id,
        regionCode: this.regionAndDepartmentInfo.region.code,
        cznId: this.regionAndDepartmentInfo.department.id,
        period: null,
        value: JSON.stringify(files),
        type: 'FILES'
      })
      this.$forceUpdate()
    },

    async addAttachment(file){
      this.loaderDialog = true
      const fileInfo = await uploadFile(file, 100)
      const attachedFiles = this.getFiles()
      attachedFiles.push({id: fileInfo.id, name: fileInfo.name})
      this.setFiles(attachedFiles)
      this.loaderDialog = false
    },

    deleteAttachment(attachment){
      const attachedFiles = this.getFiles()
      attachedFiles.splice(attachedFiles.findIndex(e=>e.id===attachment.id), 1)
      this.setFiles(attachedFiles)
    },

    async downloadAttachment(attachment) {
      this.loaderDialog = true
      await downloadFile(attachment.id, attachment.name)
      this.loaderDialog = false
    },

    async submit() {
      if (!this.isValidValuesList())
        return

      this.submitButtonDisabled = true
      let req = await saveIndicators(this.passportCznValues)
      if (req.ok) {
        this.$emit('updated')
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
      }
    },

    isValidValuesList() {
      for (let v of this.passportCznValues.filter(e=>e.type==='VALUE')) {
        if (this.isValidValue(v.value) !== true) {
          console.log('isValidValuesList false')
          return false
        }
      }
      return true
    },

    isValidValue(value) {
      if (value == null || value == "")
        return true
      if (this.indicatorConfig.dataType === 'DECIMAL') {
        if (!this.isNumeric(value)) {
          return "Некорректное число"
        }
      } else if (this.indicatorConfig.dataType === 'INTEGER') {
        if (!this.isNormalInteger(value)) {
          return "Некорректное целое число"
        }
      } else if (this.indicatorConfig.dataType === 'TEXT') {
        if (value.length > 4000) {
          return "Длина текста не может превышать 4000 символов"
        }
      }
      return true
    },
    isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      let replacedCommaStr = Number(str.replace(/,/, '.')); //замена запятой на точку для проверки
      return !isNaN(replacedCommaStr) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(replacedCommaStr)) // ...and ensure strings of whitespace fail
    },
  },

  filters: {
    dateTimeFormat: formatTimestamp,
    dateFormat: formatDate
  },

  async beforeMount() {
    let req = await getCznIndicatorsByIndicatorId(this.domain, this.regionAndDepartmentInfo.department.id, this.indicatorConfig.id)
    if (req.ok) {
      this.passportCznValues = req.payload
    } else {
      alert('Ошибка загрузки данных')
    }
    this.periods = generatePeriodsBetween(this.maxPeriod, this.minPeriod, this.periodSpan)
    this.apiLoaded = true
  }
}
</script>
